import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; /* si chgt de lien, ne pas réactualiser toute la page (rerender function) */
import { useMediaQuery } from "react-responsive";
import axios from "../../node_modules/axios/index";
import Product from "../components/Product";
import Rating from "../components/Rating";
import Reviews from "../components/Reviews";
import { useAuth } from "../AuthContext";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";

export default function SearchScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [channelsHPDoc, setChannelsHPDoc] = useState("");
  const [channelsHPDoc2, setChannelsHPDoc2] = useState("");
  const [channelsHPDoc3, setChannelsHPDoc3] = useState("");
  const [channelsHPDoc4, setChannelsHPDoc4] = useState("");
  const [channelsHPDoc5, setChannelsHPDoc5] = useState("");

  const [reviewList, setReviewList] = useState("");

  /* const [similarChannelWeek, setSimilarChannelWeek] = useState("");  */

  const { currentUser } = useAuth();

  const isMobile = !useMediaQuery({
    query: "(min-width: 952px)",
  });

  function getRandomElements(arr) {
    const shuffled = arr
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    return shuffled.slice(0, 9);
  }

  useEffect(() => {
    async function getChannels(x, setFunction) {
      let response2 = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products/channels/${x}/getvideos/false`
      );
      const channelsDoc = [response2.data];
      setFunction(channelsDoc);
    }

    async function getReviews(x) {
      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `reviews/getreviews/id/${x}/user/all/order/latest/type/all/moderation/true`
      );
      const reviewList = response.data;
      setReviewList(reviewList);
    }

    getChannels(
      getRandomElements([
        "61c8c49d7e6b8c2d783877ea",
        "646604bc7a4fc0ff8b4d0136",
        "63b893f9e0f22c53255b742c",
        "63acadf11f9e9229a0861a84",
        "639e588f1f9e9229a0861a5c",
        "66cb26e80695848ebb2a8aae",
        "655c8c328a3d2f0bc173fa5d",
        "63741eb5afe389b1ac38ce99",
        "655c8dbf8a3d2f0bc173faaa",
        "63595735099f3f70abf9c56b",
        "63741eb4afe389b1ac38ce96",
        "639912491f9e9229a0861a30",
        "639e58901f9e9229a0861a60",
        "639912481f9e9229a0861a2d",
        "643f24883f5ca9a3beebd51d",
        "63bc2e14e0f22c53255b7449",
        "63acadf01f9e9229a0861a82",
        "63b56719e0f22c53255b73fc",
        "637abcd7afe389b1ac38cef6",
        "63625b52099f3f70abf9c57c",
        "648f89c50838f7e8432fa03e",
        "63b5670de0f22c53255b73cb",
        "651b2a84f39f63cd157e9b14",
        "64708a799c4ca8628643beb9",
        "6626ba3463c44240be05fbdb",
      ]).join("_"),
      setChannelsHPDoc
    );

    getReviews(
      "6709fba879a9d6006bcc77bc_6701934579a9d6006bc77e06_67008ed479a9d6006bc6f3cb_66fb9a7679a9d6006bc3e2aa_66e03dd51df963006b0c03e2_66d7bec5bffb6a006ce52ea1"
    );

    getChannels(
      "63b5671ae0f22c53255b7401_6387999dafe389b1ac38cf87_638799a1afe389b1ac38cf92_639912491f9e9229a0861a30_639912481f9e9229a0861a2d_63dafa5be0f22c53255b7569_63c7c805e0f22c53255b74a8",
      setChannelsHPDoc2
    );

    getChannels(
      getRandomElements([
        "63acadf11f9e9229a0861a84",
        "639e588f1f9e9229a0861a5c",
        "63b71591e0f22c53255b7410",
        "63741eb4afe389b1ac38ce93",
        "6466071d7a4fc0ff8b4d013b",
        "63a0f1a31f9e9229a0861a6e",
        "63acaded1f9e9229a0861a7e",
        "6466072b7a4fc0ff8b4d0141",
        "63879995afe389b1ac38cf6e",
        "63d06a76e0f22c53255b7535",
        "61c8c49d7e6b8c2d783877ea",
        "6335bdd073a6680004d1d8f2",
        "639e588d1f9e9229a0861a55",
        "6626ba3463c44240be05fbdb",
      ]).join("_"),
      setChannelsHPDoc3
    );

    getChannels(
      "63e2a0d131201fb6f183a578_63b71590e0f22c53255b740b_63a0f1a31f9e9229a0861a6e_643c66ddd9fedba3b19d8c54_63b56712e0f22c53255b73df_6390aa983ced33693653c9ef_63c7c81ce0f22c53255b74e9",

      setChannelsHPDoc4
    );

    /* New on Favoree Section */
    async function getLatestChannels() {
      let response = await axios.get(
        process.env.REACT_APP_API_ADDRESS +
          `products?name=&category=&country=&min=0&max=0&rating=0&ratingFun=0&ratingLearn=0&ratingEmotional=0&order=latest&language=English&allTopics=&subTopic=&rankingRatingMoodDef=&context=&level=&platform=&tag=&duration=&subscriber=&limit=8`
      );
      const channelList = [response.data];

      setChannelsHPDoc5(channelList);
    }

    getLatestChannels();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Favoree - Discover and review YouTube channels</title>
        <meta
          name="description"
          content="Review and find YouTube channels with powerful search, filters and much more. Watch top-rated videos based on your interests."
        ></meta>

        <link rel="canonical" href="https://www.favoree.io"></link>
      </Helmet>

      <div className="cover" id="headerHP">
        <div className="coverInside">
          <div className="oneThird" id="pictureHP">
            <img
              className="headerPicture"
              src="../images/Channel_cards.png"
              alt="The best channel for every topic"
            ></img>
          </div>
          <div className="twoThird">
            <h1>A new way to explore YouTube</h1>
            <p>
              Rate and review YouTube channels, search and find quality content
            </p>
            <div className="space"></div>
            <div className="space"></div>
            <a className="buttonLightGrey" href="/search">
              Discover new channels
            </a>
          </div>
        </div>
      </div>

      <div className="coverChannels">
        <div className="coverInside">
          <div className="alignHorizontal">
            <a href="/search">
              {isMobile ? (
                <h2>Trending on Favoree</h2>
              ) : (
                <h2>
                  Trending on Favoree
                  <i
                    class="fa-solid fa-fire"
                    style={{ height: "3.4rem" }}
                  ></i>{" "}
                </h2>
              )}
            </a>
            <a className="toRight" href="/search">
              View more
            </a>
          </div>
          <div className="alignHorizontal">
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-left"
                onClick={() => {
                  document.getElementById("slide_1").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            <div className="sliderChannelsInside">
              <div className="sliderChannels">
                <div className="channelsHP">
                  {channelsHPDoc &&
                    channelsHPDoc[0]
                      .sort((a, b) => b.numReviews - a.numReviews)
                      .map((product, x) => (
                        <div id={"slide_" + x}>
                          <Product
                            key={product._id}
                            product={product}
                          ></Product>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                onClick={() => {
                  document.getElementById("slide_8").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            {isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                id="chevronMobile"
                onClick={() => {
                  document.getElementById("slide_8").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
          </div>
        </div>
      </div>
      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <h2>Recommended topics</h2>
          <div className="row center">
            <a
              className="recommendedTopics"
              id="pink"
              href="/top/best-rated-science-youtubers"
            >
              <i className="fa-solid fa-flask"></i>
              <h3>Science</h3>
            </a>
            <a
              className="recommendedTopics"
              id="darkPurple"
              href="/search/allTopics/History%20and%20Geography/subTopic/War_History_Archeology"
            >
              <i className="fa-solid fa-archway"></i>
              <h3>History</h3>
            </a>
            <a
              className="recommendedTopics"
              id="darkBlueI"
              href="/search/allTopics/History%20and%20Geography_Health%20and%20Wellbeing/subTopic/Travel_Nature"
            >
              <i className="fa-solid fa-route"></i>
              <h3>Travel</h3>
            </a>
            <a
              className="recommendedTopics"
              id="green"
              href="/search/allTopics/Business%20and%20Economics"
            >
              <i className="fa-solid fa-sack-dollar"></i>
              <h3>Business</h3>
            </a>
            <a
              className="recommendedTopics"
              id="redI"
              href="/search/allTopics/Cinema%20and%20TV/subTopic/Cinema"
            >
              <i className="fa-solid fa-film"></i> <h3>Cinema</h3>
            </a>
            <a
              className="recommendedTopics"
              id="darkGreen"
              href="/top/best-rated-gaming-youtube-channels"
            >
              <i className="fa-solid fa-gamepad"></i> <h3>Gaming</h3>
            </a>

            <a
              className="recommendedTopics"
              id="lightBlue"
              href="/search/allTopics/Politics%20and%20Law/subTopic/Crimes%20and%20Scams"
            >
              <i className="fa-solid fa-person-falling-burst"></i>
              <h3>True crime</h3>
            </a>
            <a
              className="recommendedTopics"
              id="darkRedI"
              href="/top/best-rated-sport-youtube-channels"
            >
              <i className="fa-solid fa-futbol"></i> <h3>Sport</h3>
            </a>
          </div>
        </div>
      </div>

      <div className="coverChannels">
        <div className="coverInside" id="coverReviews">
          <div className="alignHorizontal">
            <h2>Top reviews this month</h2>{" "}
            <a className="toRight" href="/latest-reviews">
              View more
            </a>
          </div>
          {reviewList && (
            <Reviews
              reviewList={reviewList.slice(0, isMobile ? 3 : 6)}
              currentUser={currentUser}
            ></Reviews>
          )}
        </div>
      </div>

      <div className="coverChannels">
        <div className="coverInside">
          <div className="alignHorizontal">
            <a href="/search/category/all/country/all/name/all/min/0/max/0/rating/4/ratingFun/0/ratingLearn/0/ratingEmotional/0/order/highest/language/English/allTopics/all/subTopic/all/rankingRatingMoodDef/all/context/all/level/all/platform/all/tag/all/duration/all/subscriber/all">
              <h2>Best rated channels</h2>
            </a>
            <a
              className="toRight"
              href="/search/category/all/country/all/name/all/min/0/max/0/rating/4/ratingFun/0/ratingLearn/0/ratingEmotional/0/order/highest/language/English/allTopics/all/subTopic/all/rankingRatingMoodDef/all/context/all/level/all/platform/all/tag/all/duration/all/subscriber/all"
            >
              View more
            </a>
          </div>
          <div className="alignHorizontal">
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-left"
                onClick={() => {
                  document.getElementById("slideE_1").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            <div className="sliderChannelsInside">
              <div className="sliderChannels">
                <div className="channelsHP">
                  {channelsHPDoc3 &&
                    channelsHPDoc3[0]
                      .sort((a, b) => b.numReviews - a.numReviews)

                      .map((product, x) => (
                        <div id={"slideE_" + x}>
                          <Product
                            key={product._id}
                            product={product}
                          ></Product>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                onClick={() => {
                  document.getElementById("slideE_8").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            {isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                id="chevronMobile"
                onClick={() => {
                  document.getElementById("slideE_8").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <div>
          {" "}
          <div className="space"></div>
          <div className="space"></div>
        </div>
      )}

      <div className="cover" id="darkGrey">
        <div className="coverInside">
          <h2>Channel of the week</h2>

          <div className="channelOfWeek">
            <div className="greyRadius">
              <div className="fortyPercent">
                <div className="space"></div>
                <div className="space"></div>
                <Link to="/channel/caddicarus-638799a3afe389b1ac38cf9c">
                  <div className="alignHorizontal">
                    <img
                      className="smallRound"
                      src="https://yt3.ggpht.com/ytc/AMLnZu-YidCi4CJ697E8teUYxtTVvDc-Dwsjgj_aVuwC-w=s240-c-k-c0x00ffffff-no-rj"
                      alt="Logo of Caddicarus"
                    ></img>

                    <div className="space"></div>
                    <h3>Caddicarus</h3>
                  </div>
                </Link>
                <a href="/channel/caddicarus-638799a3afe389b1ac38cf9c">
                  <Rating rating={4.4} numReviews={16} mean={true}></Rating>
                </a>
                <div className="space"></div>
                <p>
                  Caddicarus is a YouTube channel created by British content
                  creator Jim Caddick, also known as Caddy. The channel
                  primarily focuses on video game reviews, retrospectives, and
                  comedic sketches, with a special emphasis on PlayStation
                  titles from the PS1 and PS2 eras. The channel often explores
                  nostalgic and obscure games, offering a mix of entertainment
                  and insightful commentary for fans of classic gaming.
                </p>
                <div className="space"></div> <div className="space"></div>
                <div className="alignHorizontal">
                  <a
                    className="buttonLightGrey"
                    href="/channel/caddicarus-638799a3afe389b1ac38cf9c"
                  >
                    {" "}
                    Review this channel
                  </a>
                </div>
                <div className="space"></div>
                <a
                  target="_blank"
                  href="https://discord.gg/zfudkGhxzH"
                  rel="noopener noreferrer"
                >
                  <i class="fa-brands fa-discord"></i> Join our Discord to vote
                  for the channel of the week
                </a>
                <div className="space"></div>
              </div>
              <div className="fortyPercent" id="max-width-680">
                <Link to="/channel/caddicarus-638799a3afe389b1ac38cf9c">
                  <img
                    className="large"
                    src="https://favoree-images-us.s3.us-west-2.amazonaws.com/Caddicarus.jpeg"
                    alt="Caddicarus"
                  ></img>
                </Link>
              </div>
              <div className="space"></div>
            </div>
          </div>
        </div>
        <div className="space"></div>
      </div>
      <div className="space"></div>

      <div className="coverChannels">
        <div className="coverInside">
          <div className="alignHorizontal">
            <a href="/search/category/all/name/all/min/0/max/0/rating/0/ratingFun/0/ratingLearn/0/ratingEmotional/0/order/latest/language/English/allTopics/all/subTopic/all/rankingRatingMoodDef/all/context/all/level/all/platform/all/tag/all/duration/all">
              <h2>New on Favoree</h2>
            </a>
            <a
              className="toRight"
              href="/search/category/all/name/all/min/0/max/0/rating/0/ratingFun/0/ratingLearn/0/ratingEmotional/0/order/latest/language/English/allTopics/all/subTopic/all/rankingRatingMoodDef/all/context/all/level/all/platform/all/tag/all/duration/all"
            >
              View more
            </a>
          </div>
          <div className="alignHorizontal">
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-left"
                onClick={() => {
                  document.getElementById("slideb_1").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            <div className="sliderChannelsInside">
              <div className="sliderChannels">
                <div className="channelsHP">
                  {channelsHPDoc5 &&
                    channelsHPDoc5[0]
                      .sort((a, b) => b.numReviews - a.numReviews)

                      .map((product, x) => (
                        <div id={"slideb_" + x}>
                          <Product
                            key={product._id}
                            product={product}
                          ></Product>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                onClick={() => {
                  document.getElementById("slideb_6").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            {isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                id="chevronMobile"
                onClick={() => {
                  document.getElementById("slideb_6").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
          </div>
        </div>
      </div>

      <div className="coverChannels">
        <div className="coverInside">
          <div className="alignHorizontal">
            <a href="/search/allTopics/History%20and%20Geography/subTopic/Archeology_History_War">
              <h2>Top history channels</h2>
            </a>
            <a
              className="toRight"
              href="/search/allTopics/History%20and%20Geography/subTopic/Archeology_History_War"
            >
              View more
            </a>
          </div>
          <div className="alignHorizontal">
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-left"
                onClick={() => {
                  document.getElementById("slideD_1").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            <div className="sliderChannelsInside">
              <div className="sliderChannels">
                <div className="channelsHP">
                  {channelsHPDoc4 &&
                    channelsHPDoc4[0]
                      .sort((a, b) => b.numReviews - a.numReviews)

                      .map((product, x) => (
                        <div id={"slideD_" + x}>
                          <Product
                            key={product._id}
                            product={product}
                          ></Product>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                onClick={() => {
                  document.getElementById("slideD_6").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            {isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                id="chevronMobile"
                onClick={() => {
                  document.getElementById("slideD_6").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
          </div>
        </div>
      </div>
      <div className="cover" id="darkGrey">
        <div className="space"></div>

        <div className="coverInside">
          <h2>Why use Favoree?</h2>
          <div className="twoThird">
            <h3>New ways to discover content</h3>
            <p>Channel reviews and ratings by the community</p>
            <div className="space"></div>
            <p>Simple filters to find new channels and videos</p>
            <div className="space"></div>
            <p>
              Additional content qualification: topics and subtopics, average
              duration, popularity, mood...
            </p>
            <div className="space"></div>
            <p>Top similar channels to your favorite one</p>
          </div>

          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="../images/menu_filters3.png"
                alt="Choose YouTube channels with filters"
              ></img>
            </div>
          )}
        </div>

        <div className="space"></div>
        <div className="space"></div>

        <div className="space"></div>

        <div className="coverInside">
          {!isMobile && (
            <div className="oneThird" id="leftAlign">
              <img
                className="headerPicture"
                src="../images/coverBrowse.png"
                alt="Watch the best videos on Favoree"
              ></img>
            </div>
          )}
          <div className="twoThird">
            <h3>Diverse and quality content</h3>
            <p>
              Channels are analysed and qualified thanks to machine-learning and
              social media data (YouTube, Twitter, Reddit...)
            </p>

            <div className="space"></div>
            <p>Preference for long and in-depth formats</p>

            <div className="space"></div>
            <p>No priority on most-viewed channels</p>
            <div className="space"></div>
            <p>
              No algorithm based on previous usage that usually locks you up
              inside the same topics and channel suggestions
            </p>
            <div className="space"></div>
            <p>
              Avoid witless content distractions: we help you find quality
              channels
            </p>
          </div>
          <div className="space"></div> <div className="space"></div>{" "}
          <div className="space"></div>
        </div>
      </div>
      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h4>Can't find your favorite channel?</h4>
            <div className="space"></div>

            <a className="buttonLightGrey" id="darkBlue" href="/add-channel">
              {" "}
              Add new channel
            </a>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="/images/addChannel.png"
                alt="Add a Channel"
              ></img>
            </div>
          )}
        </div>
      </div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="coverChannels">
        <div className="coverInside">
          <div className="alignHorizontal">
            <a href="/search/allTopics/Games/subTopic/Gaming_Consoles_Retrogaming">
              <h2>Gaming channels</h2>
            </a>
            <a
              className="toRight"
              href="/search/allTopics/Games/subTopic/Gaming_Consoles_Retrogaming"
            >
              View more
            </a>
          </div>
          <div className="alignHorizontal">
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-left"
                onClick={() => {
                  document.getElementById("slideC_1").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            <div className="sliderChannelsInside">
              <div className="sliderChannels">
                <div className="channelsHP">
                  {channelsHPDoc2 &&
                    channelsHPDoc2[0]
                      .sort((a, b) => b.numReviews - a.numReviews)
                      .map((product, x) => (
                        <div id={"slideC_" + x}>
                          <Product
                            key={product._id}
                            product={product}
                          ></Product>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {!isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                onClick={() => {
                  document.getElementById("slideC_6").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
            {isMobile && (
              <i
                className="fa-solid fa-chevron-right"
                id="chevronMobile"
                onClick={() => {
                  document.getElementById("slideC_6").scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }}
              ></i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
